import React, { ReactNode } from 'react';

import styled from '@emotion/styled';

import {
  Box,
  Button,
  COLORS,
  FontWeight,
  mq,
  Text,
  toggleStyleValue,
} from '@clutter/clean';
import { useSharedCheckoutContext } from '@root/components/checkout/context';
import { Currency } from '@shared/currency';

import { Superscript } from './superscript';

import mostPopularBadge from '@images/illustrations/most_popular.svg';

const MostPopularBadge = styled.img`
  position: absolute;
  right: 8px;
  width: 44px;

  ${mq({
    top: ['0px', null, null, '4px'],
  })}
`;

const CTAContainer = styled.div`
  display: flex;
  border-top: 1px solid ${COLORS.grayBorder};
  padding: 12px 16px;
  align-items: center;
  justify-content: space-between;
`;

const ValuePropIcon = styled.img`
  max-height: 32px;
  ${mq({
    width: ['24px', '36px'],
  })}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 36px auto;
  align-items: center;
  justify-items: center;
  gap: 8px;
  min-height: 34px;
`;

const Description = styled(Text.Caption)`
  text-align: left;
  width: 100%;
`;

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  overflow: hidden;
  max-width: 400px;
  background: ${({ selected }) =>
    selected ? COLORS.tealBackground : COLORS.cloud};
  box-shadow: ${toggleStyleValue(
    'selected',
    '0 0 0 2px ' + COLORS.tealPrimary,
    '0 0 0 1px ' + COLORS.grayBorder,
  )};
`;

const Callout = styled(Text.Callout)`
  ${mq({
    fontSize: ['16px', '14px'],
  })}
`;

const Banner: React.FC<{
  value: string;
  selected: boolean;
  showBadge: boolean;
}> = ({ value, selected, showBadge }) => (
  <Box
    background={selected ? COLORS.tealPrimary : COLORS.tealBackground}
    padding="4px 16px"
    position="relative"
  >
    {showBadge && <MostPopularBadge src={mostPopularBadge} />}
    <Text.Caption
      color={selected ? COLORS.cloud : COLORS.tealPrimary}
      weight={FontWeight.Medium}
    >
      {value}
    </Text.Caption>
  </Box>
);

const LaborRate: React.FC<{
  free: boolean;
  laborRate: number;
  disclaimer?: boolean;
  showDiscount?: boolean;
}> = ({ free, laborRate, disclaimer, showDiscount = true }) => (
  <Box>
    {showDiscount && (
      <Callout
        color={free ? COLORS.hippo : COLORS.tealPrimary}
        weight={FontWeight.Medium}
        display="inline"
        textDecoration={free ? 'line-through' : 'none'}
      >
        <Currency
          amount={laborRate}
          suffix={disclaimer ? '/hr *' : '/hr'}
          precision={0}
        />
      </Callout>
    )}
    {free && (
      <>
        {showDiscount && <>&nbsp;</>}
        <Text.Callout
          display="inline"
          weight={FontWeight.Medium}
          color={COLORS.tealPrimary}
        >
          FREE
        </Text.Callout>
        <Superscript>1</Superscript>
      </>
    )}
  </Box>
);

type ValuePropsData = {
  title: React.ReactNode;
  values: Array<{ icon: string; description: React.ReactNode }>;
};

const ValueProps: React.FC<{
  data: ValuePropsData;
}> = ({ data: { values, title } }) => {
  return (
    <Box.Flex flexDirection="column" gap="16px">
      <Text.Caption weight={FontWeight.Medium} color={COLORS.hippo}>
        {title}
      </Text.Caption>
      <Box.Flex flexDirection="column">
        {values.map((value, i) => (
          <Grid key={i}>
            <ValuePropIcon src={value.icon} />
            <Description color={COLORS.hippo}>{value.description}</Description>
          </Grid>
        ))}
      </Box.Flex>
    </Box.Flex>
  );
};

export const Card: React.FC<{
  banner: string;
  flatFee?: number;
  freeDuration: number;
  laborRate?: number;
  onSelect(): void;
  selected: boolean;
  showBadge?: boolean;
  showPrice: boolean;
  subtitle: string;
  testId: string;
  title: React.ReactNode;
  valueProps: ValuePropsData;
  disclaimer?: ReactNode;
}> = ({
  banner,
  flatFee,
  freeDuration,
  laborRate,
  onSelect,
  selected,
  showBadge,
  showPrice,
  subtitle,
  testId,
  title,
  valueProps,
  disclaimer,
}) => {
  const { simplifiedTermsVariant } = useSharedCheckoutContext();
  return (
    <Container selected={selected} onClick={onSelect} data-test-id={testId}>
      <Banner value={banner} selected={selected} showBadge={!!showBadge} />
      <Box.Flex
        flexDirection="column"
        padding="12px 16px"
        height="100%"
        gap="8px"
      >
        <Box.Flex
          flexDirection={['row', null, null, 'column']}
          alignItems={['center', null, null, 'unset']}
          gap={['12px', null, null, 'unset']}
          justifyContent="space-between"
        >
          <Box.Flex flexDirection="column" margin="0 0 8px">
            <Text.Title size="extraSmall">{title}</Text.Title>
            <Text.Caption color={COLORS.hippo}>{subtitle}</Text.Caption>
          </Box.Flex>
          <Box.Flex
            justifyContent="space-between"
            margin="0 0 12px"
            gap="4px"
            flexDirection={['column', 'row', 'column', 'row']}
          >
            {!!laborRate &&
              showPrice &&
              (!simplifiedTermsVariant || !flatFee) && (
                <LaborRate
                  laborRate={laborRate}
                  free={freeDuration > 0}
                  disclaimer={disclaimer !== undefined}
                  showDiscount={!simplifiedTermsVariant}
                />
              )}
            {!!flatFee && showPrice && (
              <Text.Callout
                weight={FontWeight.Medium}
                color={COLORS.tealPrimary}
              >
                {!simplifiedTermsVariant && '+'}
                <Currency amount={flatFee} /> flat fee
              </Text.Callout>
            )}
          </Box.Flex>
          {disclaimer}
        </Box.Flex>
        <ValueProps data={valueProps} />
      </Box.Flex>
      <CTAContainer>
        <Button
          fullWidth
          onClick={(e) => {
            e.stopPropagation();
            onSelect();
          }}
          size="small"
          kind={selected ? 'primary' : 'secondary'}
        >
          {selected ? 'Selected' : 'Select'}
        </Button>
      </CTAContainer>
    </Container>
  );
};
