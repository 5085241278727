import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import * as Sentry from '@sentry/browser';

import {
  Box,
  BREAKPOINTS,
  COLORS,
  EXTRA_SMALL_TITLE_STYLES,
  FontWeight,
  mq,
  SkeletonLoader,
  SMALL_TITLE_STYLES,
  Text,
  TextButton,
} from '@clutter/clean';
import {
  AddressFragment,
  PlanFragment,
  PlanKindEnum,
  RateGroupFragment,
  RateGroupKindEnum,
} from '@graphql/platform';
import { useSharedCheckoutContext } from '@root/components/checkout/context';
import { clearStoragePlan } from '@root/components/checkout/helpers/moving';
import { StorageCheckoutStepProps } from '@root/components/checkout/types';
import { PLANS } from '@root/constants/pricing';
import { useTrack } from '@root/initializers/wt';
import { PlanKey } from '@root/resources/types/plan_key';
import { EventSchema } from '@root/resources/wt/types';
import { Fallback } from '@shared/fallback';
import { useStoragePriceCompletedFunnelEvent } from '@utils/hooks/funnel_events/use_storage_price_completed_funnel_event';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';
import { usePricingSetForZIP } from '@utils/hooks/pricing';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';

import { PlanEstimatorModal } from '../../subcomponents/plan_estimator_modal';
import { RadioCard } from '../../subcomponents/radio_card';
import { SkipButton } from '../../subcomponents/skip_button';

import { GridTooltip } from './plan_size/grid_tooltip';

import mostPopular from '@images/illustrations/most_popular.svg';

type PlanSummary = {
  plan: PlanFragment;
  amount: number;
  rateGroup: RateGroupFragment;
};

const MostPopular = styled.img`
  position: absolute;
  right: -8px;
  top: -8px;
  width: 44px;

  ${mq({
    width: ['44px', null, '50px'],
    right: ['-8px', null, '-12px'],
    top: ['-10px', null, '-12px'],
  })}
`;

const PlanCards = styled.div`
  display: grid;

  ${mq({
    marginTop: ['32px', '24px'],
    gridTemplateColumns: ['1fr 1fr', '1fr 1fr 1fr'],
    gridGap: ['12px', '16px'],
  })}
`;

const Card = styled(RadioCard)`
  &:last-of-type {
    grid-column-end: span 2;
  }

  @media (min-width: ${BREAKPOINTS.SM}) {
    grid-column-end: span 1 !important;
  }
`;

export const DESKTOP_PLAN_SELECTOR_SIZE = '356px'; // card height * 3 + gap * 2

let hasScrolled = false;

type PlanSizeStepProps = StorageCheckoutStepProps & {
  movingCheckoutFlow: boolean;
  movingExtendedDestinationServiceArea?: boolean;
  endAddress?: Partial<AddressFragment>;
};

export const PlanSize: React.FC<PlanSizeStepProps> = ({
  onChange,
  scrollToStep,
  values: {
    planSize,
    zip,
    dateScheduled,
    commitment = RateGroupKindEnum.Saver,
    skipStoragePlanSizeSelected,
    currentStorageCustomer,
  },
  movingCheckoutFlow,
  movingExtendedDestinationServiceArea,
  endAddress,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [recommendedPlan, setRecommendedPlan] = useState<PlanKey | null>(null);
  const { isMobile } = useBreakpoints();

  const track = useTrack({ action: 'click' });
  const { simplifiedTermsVariant } = useSharedCheckoutContext();
  useEffect(() => {
    if (simplifiedTermsVariant && commitment === RateGroupKindEnum.Saver) {
      onChange('commitment', RateGroupKindEnum.Flexer);
    }
  }, []);

  const totalColumns = isMobile ? 2 : 3;
  const planIndex = recommendedPlan
    ? Object.keys(PLANS).indexOf(recommendedPlan)
    : -1;
  const tooltipRow = Math.floor(planIndex / totalColumns) + 1;
  const columnIndex = planIndex % totalColumns;

  const trackFunnelEvent = useTrackFunnelEvents();
  const trackStoragePriceCompleted = useStoragePriceCompletedFunnelEvent();

  const handleChange = (
    size: PlanKey,
    amount?: number,
    preventScroll?: boolean,
  ) => {
    if (amount) {
      trackStoragePriceCompleted({
        planSize: size,
        commitment,
        amount,
      });
    } else {
      Sentry.captureMessage(
        `Selected plan ${size} without prices`,
        Sentry.Severity.Warning,
      );
    }
    if (!hasScrolled && !preventScroll) {
      hasScrolled = true;
      scrollToStep('next');
    }

    onChange('planSize', size);
    onChange('commitment', commitment); // Commitment selection should be defaulted after selecting a plan
    onChange('skipStoragePlanSizeSelected', false);

    // Changing plan size potentially impacts availability so we proactively reset.
    if (dateScheduled) onChange('dateScheduled', undefined);
  };

  const storagePricingGroupEntries = usePricingSetForZIP(
    endAddress?.zip || zip,
  )?.storagePricingGroupEntries;

  const planMap = storagePricingGroupEntries
    ?.filter(
      (pge) =>
        pge.rateGroup?.name === commitment &&
        pge.pricing.plan.kind === PlanKindEnum.Fixed,
    )
    ?.reduce<Record<string, PlanSummary>>((acc, pge) => {
      acc[pge.pricing.plan.name] = {
        plan: pge.pricing.plan,
        amount: pge.pricing.amount,
        rateGroup: pge.rateGroup!,
      };
      return acc;
    }, {});

  useEffect(() => {
    storagePricingGroupEntries &&
      storagePricingGroupEntries.length > 0 &&
      trackFunnelEvent({
        schema: EventSchema.WWW__StoragePriceViewed,
        action: 'display',
        metadata: {},
      });
  }, [storagePricingGroupEntries, trackFunnelEvent]);

  const onSkipStoragePlanSize = () => {
    clearStoragePlan(onChange);
    scrollToStep();
  };

  const visible =
    !movingCheckoutFlow ||
    (movingCheckoutFlow &&
      !currentStorageCustomer &&
      !movingExtendedDestinationServiceArea);

  useEffect(() => {
    onChange('skipStoragePlanSizeSelected', !visible);
  }, [visible]);

  if (!visible) {
    return null;
  }

  return (
    <Box margin={movingCheckoutFlow ? ['48px 0 0', null, '72px 0 0'] : []}>
      <Box.Grid
        gridTemplateColumns={
          movingCheckoutFlow
            ? ['1fr', '1fr', '1fr', '3fr 1fr']
            : ['1fr', '2fr 1fr']
        }
      >
        <Box.GridItem>
          <Text.Title size="small" color={COLORS.tealDark}>
            {movingCheckoutFlow
              ? 'Need storage? First month free'
              : 'Select storage size'}
          </Text.Title>
        </Box.GridItem>
        <Box.GridItem
          textAlign={['left', 'left', 'left', 'right']}
          style={{ alignSelf: 'center' }}
        >
          <TextButton
            onClick={() => {
              setShowModal(true);
              track({
                objectName: 'plan_size_selector',
                label: 'Help me pick a size',
                objectType: 'button',
              });
            }}
          >
            Help me pick a size
          </TextButton>
        </Box.GridItem>
      </Box.Grid>
      {movingCheckoutFlow && (
        <Box margin="4px 0 0">
          <Text.Body color={COLORS.storm}>
            {endAddress?.city
              ? `Whatever you don’t need at your new place, we’ll store at our facility closest to ${endAddress.city}. `
              : 'We’ll store anything you don’t need in your new place. '}
            Your plan size will be automatically downgraded if you end up
            needing less storage space.
          </Text.Body>
        </Box>
      )}
      <PlanCards>
        {recommendedPlan && (
          <Box.GridItem gridColumn={['span 2', 'span 3']} gridRow={tooltipRow}>
            <GridTooltip columnIndex={columnIndex}>
              <Box.Flex justifyContent="space-between">
                <Text.Title size="extraSmall">
                  We recommend a {PLANS[recommendedPlan].label} unit
                </Text.Title>
                <TextButton onClick={() => setRecommendedPlan(null)}>
                  <span style={{ color: COLORS.cloud }}>Close</span>
                </TextButton>
              </Box.Flex>
              <Text.Body>
                This is based on your selections. Don’t worry, we can easily
                upgrade or downgrade your plan when we arrive!
              </Text.Body>
            </GridTooltip>
          </Box.GridItem>
        )}
        {Object.entries(PLANS).map(([key, { label, description }]) => (
          <Card
            key={key}
            selected={key === planSize}
            onChange={() => {
              const planSummary = planMap?.[key];
              handleChange(key as PlanKey, planSummary && planSummary.amount);
            }}
            value={key}
            name="planSize"
          >
            <Box.Flex
              textAlign="center"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Text
                style={
                  isMobile ? EXTRA_SMALL_TITLE_STYLES.SM : SMALL_TITLE_STYLES.SM
                }
              >
                {label}
              </Text>
              <Text.Callout color={COLORS.hippo}>{description}</Text.Callout>
              <Box margin="4px 0 0 ">
                <Text.Callout
                  weight={FontWeight.Medium}
                  color={COLORS.tealPrimary}
                >
                  <Fallback
                    delayMs={0}
                    placeholder={<Box height="19px" />}
                    loader={
                      <SkeletonLoader
                        height="19px"
                        width="90px"
                        margin="0 auto"
                      />
                    }
                    value={planMap?.[key]}
                  >
                    {planMap?.[key] && `$${planMap?.[key]?.amount}/mo`}
                  </Fallback>
                </Text.Callout>
              </Box>
              {key === '10x10' && <MostPopular src={mostPopular} />}
            </Box.Flex>
          </Card>
        ))}
      </PlanCards>
      {movingCheckoutFlow && (
        <SkipButton
          buttonText="Skip, I don’t need storage"
          selected={!!skipStoragePlanSizeSelected}
          trackingObjectName="skip_storage_plan_size"
          name="skipStoragePlanSize"
          value="skipStoragePlanSize"
          onSkip={onSkipStoragePlanSize}
        />
      )}
      <PlanEstimatorModal
        isOpen={showModal}
        onClose={(plan) => {
          setShowModal(false);
          if (plan) {
            setRecommendedPlan(plan);
            track({
              action: 'display',
              objectType: 'tooltip',
              objectName: 'recommended_plan_size_tooltip',
              value: recommendedPlan,
            });
            const planSummary = planMap?.[plan];
            handleChange(plan, planSummary && planSummary.amount, true);
          }
        }}
      />
    </Box>
  );
};
