import React, { useState } from 'react';

import { Box, Button, COLORS, Modal, Text } from '@clutter/clean';
import { useTrack } from '@root/initializers/wt';
import { SafeSessionStorage } from '@utils/browser';
import { useStabilizedFunction } from '@utils/hooks';
import { useOnMount } from '@utils/hooks/mount';
import { useFreePickupReturnEligible } from '@utils/hooks/use_free_pickup_return_eligible';

import { useSharedCheckoutContext } from './context';

const IDLE_TIME_MS = 1000 * 60 * 2; // 2 minutes
const EXIT_INTENT_BUFFER_MS = 10000;

const SESSION_STORAGE_KEY = '__clutter:amo';

const useIdle = (cb: () => void) => {
  useOnMount(() => {
    let timeout: number | null = null;

    const resetTimeout = () => {
      if (timeout !== null) clearTimeout(timeout);
      timeout = window.setTimeout(cb, IDLE_TIME_MS);
    };

    const clickListener = () => {
      resetTimeout();
    };

    document.addEventListener('click', clickListener);
    document.addEventListener('pointerdown', clickListener);

    resetTimeout();

    return () => {
      document.removeEventListener('click', clickListener);
    };
  });
};

const useExitIntent = (cb: () => void) => {
  useOnMount(() => {
    const listener = (e: MouseEvent) => {
      if (
        // Indicates the mouse left the window
        !e.relatedTarget &&
        // Indicates that it was in the direction of the navbar/exit button
        e.clientY < 10
      ) {
        cb();
      }
    };

    const timeout = window.setTimeout(() => {
      document.addEventListener('mouseout', listener);
    }, EXIT_INTENT_BUFFER_MS);

    return () => {
      clearTimeout(timeout);
      removeEventListener('mouseout', listener);
      removeEventListener('pointerdown', listener);
    };
  });
};

export const AbandonmentModal = ({ allowOpen }: { allowOpen: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);

  const track = useTrack({ objectName: 'funnel_abandonment_modal' });

  const freePickupReturnEligible = useFreePickupReturnEligible();
  const simplifiedTermsVariant = useSharedCheckoutContext();

  const attemptOpen = useStabilizedFunction(
    (mechanism: 'idle' | 'exit_intent') => {
      const hasOpened =
        SafeSessionStorage.getItem(SESSION_STORAGE_KEY) == 'true';
      if (!hasOpened && allowOpen) {
        track({
          action: 'display',
          metadata: { trigger: mechanism },
        });
        setIsOpen(true);
        SafeSessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
      }
    },
  );

  useIdle(() => attemptOpen('idle'));
  useExitIntent(() => attemptOpen('exit_intent'));

  return (
    <Modal
      isOpen={isOpen}
      includeCloseButton
      handleModalClose={() => setIsOpen(false)}
    >
      <Box
        minWidth={['calc(100vw - 48px)', '572px']}
        width={[null, '572px']}
        padding="32px 16px 16px"
      >
        <Box margin="0 16px 20px" textAlign="center">
          <Text.Title size="large">
            {freePickupReturnEligible ? (
              <>
                Get a <Text color={COLORS.tealPrimary}>free</Text> pickup
                {!simplifiedTermsVariant && ' and delivery'}
              </>
            ) : (
              <>Lock in your appointment today!</>
            )}
          </Text.Title>
        </Box>
        <Box margin="0 12px 24px" textAlign="center">
          <Text.Body>
            {simplifiedTermsVariant ? (
              <>
                Get a free pickup when you book for 4+ months with us. Never
                visit a self-storage facility again.
              </>
            ) : freePickupReturnEligible ? (
              <>
                When you book for 4+ months with us, you will be eligible for a
                free pickup and delivery.
              </>
            ) : (
              <>
                Availability fills up fast; secure your spot now. You can
                reschedule or cancel for free up to 48 hours before your
                appointment.
              </>
            )}
          </Text.Body>
        </Box>
        <Button
          size="large"
          fullWidth
          onClick={(e) => {
            track({
              action: 'click',
              objectType: 'button',
              label: e.currentTarget.innerText,
              metadata: {
                free_pickup_return_eligible: freePickupReturnEligible,
              },
            });
            setIsOpen(false);
          }}
        >
          Continue Booking
        </Button>
      </Box>
    </Modal>
  );
};
