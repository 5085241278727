import {
  Moving__StoragePlanInput,
  PricingSetFragment,
  RateGroupKindEnum,
} from '@graphql/platform';
import { WT_VISITOR_TOKEN } from '@root/initializers/wt';
import { Commitment } from '@root/resources/types/commitment';
import { PlanKey } from '@root/resources/types/plan_key';
import { useAppointmentQuoteCreate } from '@utils/hooks/pricing/use_create_appointment_quote';
import {
  extractLaborPolicy,
  extractPlan,
  extractRateGroup,
} from '@utils/pricing/pricing_set';
import { ProtectionPlanEnum } from '@utils/protection_plan';

import { CheckoutType } from '../types';

type StoragePlanInputParameters = {
  zip?: string;
  planSize?: PlanKey;
  commitment?: Commitment;
  protectionPlan?: ProtectionPlanEnum;
  checkoutType: CheckoutType;
  pricingSet?: PricingSetFragment;
};

export const useMovingStoragePlanInput = (
  params: StoragePlanInputParameters,
) => {
  const {
    zip,
    planSize,
    commitment,
    protectionPlan,
    checkoutType,
    pricingSet,
  } = params;

  const rateGroupName = commitment ?? RateGroupKindEnum.Saver;
  const { laborRate, appointmentQuote, packageSetEntries } =
    useAppointmentQuoteCreate({
      pricingSet,
      zip,
      rateGroupName,
      planSize,
      customerToken: undefined,
    }).quote;

  if (!rateGroupName || !pricingSet) {
    return undefined;
  }

  const rateGroup = extractRateGroup(rateGroupName, pricingSet);
  const laborPolicy = extractLaborPolicy(rateGroupName, pricingSet);
  const pricingSetQuoteID = pricingSet.quoteId;
  const storagePlan = extractPlan(rateGroupName, planSize, pricingSet);
  const checkoutPackageSetEntries = packageSetEntries.map((entry) => {
    return {
      id: entry.id,
      amount: entry.amount ?? 0,
    };
  });

  if (
    !storagePlan ||
    !laborPolicy ||
    !laborRate ||
    !rateGroup ||
    !pricingSetQuoteID
  ) {
    return undefined;
  }

  return {
    planID: storagePlan.id,
    pricing: {
      appointmentQuoteID: appointmentQuote?.id,
      laborBillingFormat: laborPolicy.laborBillingFormat,
      laborPolicyID: laborPolicy.id,
      laborRateID: laborRate.id,
      packageSetEntries: checkoutPackageSetEntries,
      quoteID: pricingSetQuoteID,
    },
    protectionPlanSlug: protectionPlan,
    rateGroupID: rateGroup.id,
    subsource: `twoPage:${checkoutType}:moving`,
    visitorToken: WT_VISITOR_TOKEN,
  } as Moving__StoragePlanInput;
};
