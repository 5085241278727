import React, { useEffect } from 'react';

import { Box, COLORS, Text } from '@clutter/clean';
import {
  DoorToDoor__BundleEnum,
  LaborBillingFormatEnum,
  RateGroupKindEnum,
} from '@graphql/platform';
import { useStorageCheckoutContext } from '@root/components/checkout/context';
import { Card } from '@root/components/checkout/product_pages/steps/smart_storage/bundles/card';
import { Superscript } from '@root/components/checkout/product_pages/steps/smart_storage/bundles/superscript';
import { PerMoverHourDisclaimer } from '@root/components/checkout/product_pages/subcomponents/onboarding_fees/per_mover_hour_disclaimer';
import { StorageCheckoutStepProps } from '@root/components/checkout/types';
import { useTrack } from '@root/initializers/wt';
import { Currency } from '@shared/currency';

import tracking from '@images/illustrations/location_truck_tracking.svg';
import moverPackingBoxes from '@images/illustrations/mover_packing_boxes.svg';
import moversWithTv from '@images/illustrations/movers_with_tv_small.svg';
import truck from '@images/illustrations/moving_truck_small.svg';
import tape from '@images/illustrations/tape_multi_small.svg';
import stackedBoxes from '@images/illustrations/two_stacked_yellow_boxes_small.svg';

export const Bundles: React.FC<StorageCheckoutStepProps> = (props) => {
  const { pricingSummary, storageBundleEligible, simplifiedTermsVariant } =
    useStorageCheckoutContext();
  const {
    onChange,
    scrollToStep,
    values: { bundleKind, commitment, leadToken },
  } = props;
  const track = useTrack();

  const {
    fullServiceLaborRateAmount,
    bundles,
    freeDuration,
    formattedFreeDuration,
  } = pricingSummary || {};

  useEffect(() => {
    if (commitment === RateGroupKindEnum.Mover)
      onChange('bundleKind', undefined);
  }, [commitment]);

  if (!storageBundleEligible) return null;

  const handleChange = (value: DoorToDoor__BundleEnum) => {
    onChange('bundleKind', value);
    track({ value, label: 'Pickup package', objectName: 'package_radio_card' });
    scrollToStep('next');
  };

  const basicBundle = bundles?.find(
    (b) => b.kind === DoorToDoor__BundleEnum.Basic,
  );
  const pickupAndPackingBundle = bundles?.find(
    (b) => b.kind === DoorToDoor__BundleEnum.PickupAndPacking,
  );

  if (!basicBundle || !pickupAndPackingBundle || !fullServiceLaborRateAmount)
    return null;

  const showPrice = !!leadToken;

  const perMoverHour =
    pricingSummary?.laborBillingFormat === LaborBillingFormatEnum.PerMoverHour;

  return (
    <Box margin={['48px 0 0', null, '72px 0 0']}>
      <Box.Flex
        margin="0 0 24px"
        gap={['4px', 0, '12px']}
        flexDirection="column"
      >
        <Text.Title size="small" color={COLORS.tealDark}>
          Choose your pickup package
        </Text.Title>
        <Text.Body color={COLORS.storm}>
          We have you covered whether you want to DIY or sit back and relax!
        </Text.Body>
      </Box.Flex>
      <Box.Flex
        gap="16px"
        alignItems="center"
        flexDirection={['column', 'row', 'column', 'row']}
      >
        <Card
          banner="Lowest cost"
          freeDuration={freeDuration ?? 0}
          onSelect={() => handleChange(DoorToDoor__BundleEnum.Basic)}
          selected={bundleKind === DoorToDoor__BundleEnum.Basic}
          showPrice={showPrice}
          subtitle="You do the packing, we pick up your packed boxes and furniture."
          testId={'bundle-basic'}
          laborRate={fullServiceLaborRateAmount}
          title={
            simplifiedTermsVariant ? <>Standard &ndash; You Prepack</> : 'Basic'
          }
          valueProps={{
            title: 'Includes',
            values: [
              {
                icon: truck,
                description: 'Truck, dollies, and wrapping materials',
              },
              {
                icon: moversWithTv,
                description: 'Up to 3 full-time trained movers',
              },
              {
                icon: tracking,
                description: 'Real-time GPS tracking',
              },
            ],
          }}
          disclaimer={
            perMoverHour ? (
              <PerMoverHourDisclaimer pricingSummary={pricingSummary} />
            ) : undefined
          }
        />
        <Card
          banner="Best value"
          freeDuration={freeDuration ?? 0}
          onSelect={() => handleChange(DoorToDoor__BundleEnum.PickupAndPacking)}
          selected={bundleKind === DoorToDoor__BundleEnum.PickupAndPacking}
          showPrice={showPrice}
          subtitle="We bring boxes and packing materials, and pack everything for you!"
          testId={'bundle-pickup_and_packing'}
          showBadge
          laborRate={fullServiceLaborRateAmount}
          flatFee={pickupAndPackingBundle.packageSetEntry?.amount ?? undefined}
          title={
            simplifiedTermsVariant ? (
              <>Premium &ndash; We Do Everything</>
            ) : (
              'Pickup & Packing'
            )
          }
          valueProps={{
            title: (
              <>
                Everything in{' '}
                <Text color={COLORS.panther}>
                  {simplifiedTermsVariant ? (
                    <>
                      Standard<Superscript>1</Superscript>
                    </>
                  ) : (
                    'Basic'
                  )}
                </Text>{' '}
                plus
              </>
            ),
            values: [
              {
                icon: tape,
                description: 'Unlimited packing boxes and supplies',
              },
              {
                icon: stackedBoxes,
                description: 'We pack boxes for you',
              },
              {
                icon: moverPackingBoxes,
                description: 'Our best Move & Pack Specialists',
              },
            ],
          }}
          disclaimer={
            perMoverHour ? (
              <PerMoverHourDisclaimer pricingSummary={pricingSummary} />
            ) : undefined
          }
        />
      </Box.Flex>
      {!!formattedFreeDuration && (
        <Box margin="12px 0 0">
          <Superscript>1</Superscript>{' '}
          <Text.Caption display="inline" color={COLORS.hippo}>
            Free up to {formattedFreeDuration} and then billed hourly at{' '}
            <Currency amount={fullServiceLaborRateAmount} suffix="/hour" />. You
            can help ensure your pickup is under {formattedFreeDuration} by
            packing ahead of time!
          </Text.Caption>
        </Box>
      )}
    </Box>
  );
};
