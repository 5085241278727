import React from 'react';

import styled from '@emotion/styled';

import { LARGE_TITLE_STYLES, Text } from '@clutter/clean';
import { LaborBillingFormatEnum } from '@graphql/platform';
import { useStorageCheckoutContext } from '@root/components/checkout/context';
import { PLANS } from '@root/constants/pricing';
import { Currency } from '@shared/currency';

import { PolicyModal } from './subcomponents/policy_modal';

const Body = styled(Text.Body)`
  padding-top: 16px;
  padding-bottom: 40px;
`;

const LaborRate: React.FC<{
  amount: number;
  laborBillingFormat?: LaborBillingFormatEnum;
}> = ({
  amount,
  laborBillingFormat = LaborBillingFormatEnum.PerAppointmentHour,
}) => {
  return (
    <>
      <Currency amount={amount} />
      /hour
      {laborBillingFormat === LaborBillingFormatEnum.PerMoverHour &&
        ' per mover'}
    </>
  );
};

export const SmartStorageFinalReturnBillingModal: React.FC<{
  isOpen: boolean;
  handleModalClose(): void;
}> = ({ isOpen, handleModalClose }) => {
  const {
    pricingSummary,
    flowState: {
      values: { planSize },
    },
  } = useStorageCheckoutContext();

  const planSizeName = planSize ? PLANS[planSize].label : undefined;

  if (!pricingSummary) return null;

  const {
    finalReturnFeeAmount,
    formattedFinalReturnFreeDuration,
    fullServiceLaborRateAmount,
    laborBillingFormat,
  } = pricingSummary;

  return (
    <PolicyModal isOpen={isOpen} handleModalClose={() => handleModalClose()}>
      <Text style={LARGE_TITLE_STYLES.SM}>
        What does the final delivery cost?
      </Text>
      <Body>
        We understand that your plans may change and evolve over time. So you
        are given multiple options to close out your account.
        <br />
        <br />
        For your {planSizeName ? `${planSizeName}` : 'selected plan size'}, we
        deliver items back to you in the same service area for{' '}
        {formattedFinalReturnFreeDuration ? (
          <>
            free up to {formattedFinalReturnFreeDuration} and then billed hourly
            at{' '}
            <LaborRate
              amount={fullServiceLaborRateAmount}
              laborBillingFormat={laborBillingFormat}
            />
            {!!finalReturnFeeAmount && (
              <>
                {' '}
                plus a <Currency amount={finalReturnFeeAmount} /> service fee
              </>
            )}
          </>
        ) : (
          <>
            {!!finalReturnFeeAmount && !!fullServiceLaborRateAmount ? (
              <>
                <Currency amount={finalReturnFeeAmount} /> plus{' '}
                <LaborRate
                  amount={fullServiceLaborRateAmount}
                  laborBillingFormat={laborBillingFormat}
                />
              </>
            ) : !!finalReturnFeeAmount ? (
              <Currency amount={finalReturnFeeAmount} />
            ) : !!fullServiceLaborRateAmount ? (
              <LaborRate
                amount={fullServiceLaborRateAmount}
                laborBillingFormat={laborBillingFormat}
              />
            ) : (
              'free'
            )}
          </>
        )}
        .
        <br />
        Pickup from the storage warehouse for <Currency amount={0.75} /> per
        cuft with a <Currency amount={50} /> minimum.
        <br />
        Dispose of items for <Currency amount={50} /> plus{' '}
        <Currency amount={3.5} /> per cuft. Speciality items have stand-alone
        minimums.
      </Body>
    </PolicyModal>
  );
};
